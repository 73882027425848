import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
} from '@mui/material';

const TermsAndConditionsDialog = ({agreed, setAgreed}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAgree = () => {
    setAgreed(true);
    handleDialogClose();
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={agreed}
            onChange={(event) => setAgreed(event.target.checked)}
            name="agree"
            color="primary"
          />
        }
        label={
          <span>
            I agree to the{' '}
            <Link href="#" onClick={handleDialogOpen}>
                End User License Agreement
            </Link>
          </span>
        }
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>End User License Agreement</DialogTitle>
        <DialogContent>
          <DialogContentText>
                This End User License Agreement ("Agreement") is the agreement between you and AI Seer Pte Ltd (the “Company”) governing the use of the Facticity web application (the "Application"). By clicking "I Agree" or by accessing or using the Application, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this Agreement.
                <br></br>
                <br></br>
                <h4>1. The Application and use of Artificial Intelligence</h4>
                <br></br>
                The Application allows users to upload files and video weblinks or to input data directly into the Application. The Application utilizes artificial intelligence technology to fact-check information input by users, or to extract the relevant facts contained in the uploaded files or videos to determine, based on the artificial intelligence predictive models, whether the fact(s) is or are true, false or unverifiable. Due to current state of artificial intelligence technology and the available datasets used for the fact-checking, the Company does not guarantee the accuracy, completeness, or reliability of the fact-checking results. Users should always independently verify the results of the Application using other methodologies.
                <br></br>
                <br></br>         
                <h4>2. Beta Testing and Feedback</h4>
                <br></br>
                The Application is currently in open beta testing, and as such, may contain bugs, errors, or inaccuracies. You acknowledge and agree that your use of the Application during the open beta testing phase is at your own risk. You may provide feedback, suggestions, or comments regarding the Application’s results ("Feedback") directly to the Company by entering Feedback directly into the Application result section; or clicking the relevant icon(s) to either agree or disagree with the results of the Application. By providing any Feedback, you agree that the Company may utilize Feedback to improve the Application and its underlying models, and you grant the Company a perpetual, irrevocable, worldwide, royalty-free license to use, reproduce, distribute, and otherwise exploit the Feedback for any purpose without further reference to you.
                <br></br>
                <br></br>
                <h4>3. License Grant</h4>
                <br></br>
                The Company grants you a non-exclusive, non-transferable, revocable license to use the Application solely for your personal or internal business purposes, subject to the terms and conditions of this Agreement. 
                <br></br>
                <br></br>
                <h4>4. Scope of Use and Use Restrictions</h4>
                <br></br>
                You may use the Application only for lawful purposes and for non-commercial use, in accordance with the terms of this Agreement. You shall not use the Application in any manner that violates any applicable laws, regulations, or third-party rights. In particular, you shall not:
                <br></br>
                <br></br>
                a. input non-publicly available personal information, confidential information, sensitive information or trade secrets in the Application;
                <br></br>
                b. use the Application for any unlawful purpose or in any manner inconsistent with this Agreement;
                <br></br>
                c. use the Application in any way that could harm, disable, overburden, or impair the functioning or security of the Application, servers, or networks connected to the Application, including but not limited to engaging in any form of denial-of-service (DoS) attack, distributed denial-of-service (DDoS) attack, or any other malicious activity aimed at disrupting the availability or integrity of the Application;
                <br></br>
                d. reverse engineer, decompile, disassemble, or attempt to derive the source code of the Application;
                <br></br>
                e. modify, adapt, translate, or create derivative works based upon the Application;
                <br></br>
                f. remove, alter, or obscure any copyright, trademark, or other proprietary notices contained in the Application; and
                <br></br>
                g. share your login credentials with any third party or allow any third party to access your account.
                <br></br>
                <br></br>
                <h4>5. Intellectual Property</h4>

                The Application, including all associated intellectual property rights, is and shall remain the exclusive property of the Company. Input of data or prompts to the Application does not convey to you any rights of ownership in the Application or the Application results.
                <br></br>
                <br></br>
                <h4>6. Disclaimer and no reliance</h4>
                <br></br>
                The Application is provided "as is" and "as available", without any warranties of any kind, express or implied. To the fullest extent permitted by law, the Company disclaims all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. The Application does not, and shall not be deemed to be providing any legal, financial or medical advice or opinion.

                <br></br>
                <br></br>
                <h4>7. Limitation of Liability</h4>
                <br></br>
                To the fullest extent available under applicable law, in no event shall the Company be liable for any indirect, incidental, special, consequential, or punitive damages, arising out of or in connection with your use of the Application.
                <br></br>
                <br></br>
                <h4>8. Data Privacy</h4>
                <br></br>
                Data entered into the Application will be used to train the underlying artificial intelligence model, and such data may be accessed or reviewed by the Company or its employees, representatives or agents for training or evaluation purposes. Do not input non-publicly available personal information, confidential information, sensitive information or trade secrets in the Application. By using the Application or entering data into the Application, you consent to the collection, processing, and storage of your data in accordance with the Company’s Privacy Policy. 
                <br></br>
                <br></br>
                <h4>9. Termination</h4>
                <br></br>
                This Agreement shall remain in effect until terminated by either party. The Company may terminate this Agreement at any time without notice and/or to block or restrict your access to the Application if you breach any provision of this Agreement, in particular, clause 4 (Use Restrictions). Upon termination, you shall cease all use of the Application and delete any copies of the Application that may be in your possession.
                <br></br>
                <br></br>
                <h4>10. Governing Law </h4>
                <br></br>

                This Agreement shall be governed by and construed in accordance with the laws of Singapore, without regard to its conflict of law provisions.
                <br></br>
                <br></br>
                <h4>11. Entire Agreement</h4>
                <br></br>

                This Agreement constitutes the entire agreement between you and the Company concerning the subject matter and supersedes all prior or contemporaneous agreements, understandings, negotiations, or discussions, whether oral or written.
                <br></br>
                <br></br>
                <h4>12. Amendments</h4>
                <br></br>

                The Company reserves the right to modify or amend this Agreement at any time. Your continued use of the Application after any such changes shall constitute your consent to such changes.
            <br /><br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TermsAndConditionsDialog;
