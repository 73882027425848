import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';


async function fetchWithRetry(url, retries = 3, delay = 1000) {
  for (let i = 0; i < retries; i++) {
      try {
          const response = await fetch(url);
          if (response.ok) {
              const data = await response.json();
              return { data, ok: response.ok };
          } else {
              throw new Error('Response not ok');
          }
      } catch (error) {
          if (i === retries - 1) {
              throw error;
          }
          await new Promise(resolve => setTimeout(resolve, delay));
      }
  }
}


export const createAndCheckTask = async (query, location, timestamp, userEmail, speaker, source, mode, addMessage) => {
  console.log({executing: query})
  const createTaskUrl = 'https://fbdebate-a4xncz5jja-uc.a.run.app/ProcessEverything';
  const checkStatusUrl = (taskId) => `https://fbdebate-a4xncz5jja-uc.a.run.app/check-task-status?task_id=${taskId}`;
  var notiflist = []
  var visint = 0
  let output = null;
  let error = null;
  let loading = true;

  try {
    // Create the task
    const createResponse = await fetch(createTaskUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        location,
        timestamp,
        userEmail,
        speaker,
        source
      })
    });

    
    if (!createResponse.ok) {
      throw new Error('Failed to create task');
    }

    const createResult = await createResponse.json();

    console.log({executed: query, createResult:createResult})
    // Check if the createResponse contains Classification
    if (createResult.Classification) {
      return { output: createResult, error: null, loading: false };
    }

    const taskId = createResult.task_id;

    // Check the task status periodically
    const checkTaskStatus = async () => {
      var waittime = 0
      while (true) {
        if (waittime>30){
          if (waittime%10===0){
            try {
              const response = await fetch('https://fbdebate-a4xncz5jja-uc.a.run.app/log_error', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  query: query,
                  userEmail: userEmail,
                  message:"waited more than" + waittime + "s for the response"
                }),
              });
  
              // console.log(response)
              
            } catch (error) {
              
            }
          }
          }
        var fetchOut = await fetchWithRetry(checkStatusUrl(taskId), 5, 1000);
        var statusResult = fetchOut.data

        console.log(statusResult)
        waittime += 1;
        var tempstep = statusResult.step;
        // const statusResponse = await fetch(checkStatusUrl(taskId));
        // waittime +=1
        // const statusResult = await statusResponse.json();
        // var tempstep = statusResult.step;

        try {
          if (tempstep.length !== notiflist.length) {
            // Find new steps
            const newSteps = tempstep.slice(notiflist.length);
        
            // Update notiflist with the new steps
            notiflist = tempstep;
        
            // Post a toast for each new step
            newSteps.forEach(step => {
              if (mode === 'toast'){
                toast(step, {
                  draggable: true,
                  autoClose: 10000,
                  position: "top-right" // Align toast to the right
                });     
              }else{
                addMessage(step)
                // toast(step, {
                //   draggable: true,
                //   autoClose: 10000,
                //   position: "top-left" // Align toast to the right
                // });            
              }
            });
          } else {
            // console.log("No update needed.");
          }
        } catch (error) {
          console.error(error);
        }
        // console.log(statusResult)
        if (!fetchOut.ok || waittime > 60)  {
          output = {
            output:[],
            queries:[],
            disambiguation:"",
            status:"error",
            overall_assessment:"Query failed. Please try again",
            Classification:"",
            sources:[],
            question:query,
            location:location,
            userEmail:userEmail,
            type:"query",
            evidence:{},
            id:0
          }
          loading = false;
          try {
            const response = await fetch('https://fbdebate-a4xncz5jja-uc.a.run.app/log_error', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                query: query,
                userEmail: userEmail,
                message: "waited too long"
              }),
            });
            // console.log(response)
            
          } catch (error) {
            
          }
          break;
        }


        if (statusResult.status === 'completed') {
          output = statusResult.result;
          loading = false;
          break;
        }

        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
      }
    };

    await checkTaskStatus();
  } catch (err) {
    error = `An error occurred: ${err.message}`;
    loading = false;
  }

  return { output, error, loading };
};
