import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Dialog, DialogContent, Typography, Box } from "@mui/material";
import { purple } from "@mui/material/colors";
import GoogleIcon from '@mui/icons-material/Google';

function useQuery() {
  return new URLSearchParams(window.location.search);
}

const LoginModal = ({ open, notify, setDisplayedPage }) => {
  const { loginWithRedirect } = useAuth0();
  const query = useQuery();

  useEffect(() => {
    const error = query.get('error');
    const errorDescription = query.get('error_description');

    if (error === "access_denied") {
      notify(decodeURIComponent(errorDescription));
      console.log(decodeURIComponent(errorDescription));
    }
  }, [query, notify]);

  const handleLogin = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <Dialog open={open} onClose={() => {}} PaperProps={{ style: { minHeight: '600px', minWidth: '400px' } }}>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" style={{ minHeight: '600px' }}>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <img 
            src="https://storage.googleapis.com/public_resources_seer/seer.png" 
            alt="Facticity.AI Logo" 
            style={{ marginBottom: '40px', width: '150px', height: '37px' }}
          />
          <br></br>
             <Typography variant="h6" gutterBottom style={{ marginBottom: '30px' }}>
            Welcome to Facticity,
            <br />
            please log in to continue.
            </Typography>
          <br></br>
          <Button 
            onClick={handleLogin} 
            style={{ 
              color: 'white', 
              backgroundColor: 'black', 
              marginBottom: '20px', 
              width: '100%' 
            }}
            startIcon={<GoogleIcon />}
          >
            Sign In with Google
          </Button>
          <Button 
            onClick={handleLogin} 
            style={{ 
              color: 'white', 
              backgroundColor: 'black', 
              marginBottom: '20px', 
              width: '100%' 
            }}
          >
            Sign In
          </Button>
          <Button
            variant="outlined"
            style={{
              backgroundColor: 'white',
              color: 'black',
              boxShadow: 'none',
              width: '100%',
              marginBottom: '20px'
            }}
            onClick={() => { setDisplayedPage('signup') }}
          >
            Sign Up
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
