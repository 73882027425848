import React, { useEffect, useState, useMemo, memo} from 'react';
import { Button, Collapse, Typography, Card, CardContent, Badge } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ThumbsUpDownButtons from './thumbsupordown';
import LoadingComponent from './loadingComponent';
import LoadingComponent2 from './loadingComponentv2';
import TextField from '@mui/material/TextField';
import { purple } from "@mui/material/colors";
import Alert from '@mui/material/Alert';
import './tooltip.css';
import { marked } from 'marked';
import ScatterPlot from './plot';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import YouTubeEmbed from './video';
import ExpandComponentUpdated from './clicktoexpand_updated';
import Chip from '@mui/material/Chip';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { getYoutubeVideoDetails } from './getyoutubetitle';
import VideoProcessingLoader from './videoLoading';

const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

const VideoParagraphComponent = memo(({ id, claim, email, color, handleThumbsResponse, sendFeedback}) => {
  const [expanded, setExpanded] = useState(true);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [value, setValue] = React.useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [assessment, setAssessment] = useState("")
  const [sources, setSources] = useState([])
  const [classification, setClassification] = useState([])
  const [evidence,setEvidence] =useState({})
  const [disambiguation, setDisambiguation] = useState("")
  const [bias, setBias] = useState(null)
  const [ready, setReady] = useState(false)
  const [claims, setClaims] = useState([])
  const [count, setCount] = useState({'True':0, 'False':0, 'Inconclusive':0})
  const [total, setTotal] = useState(0)
  const [processedClaims, setProcessedClaims] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultUrl = "https://facticity-backend-a4xncz5jja-as.a.run.app"
  const [title, setTitle] = useState("")
  const [showPopup, setShowPopup] = useState(false);
  const [manualTitle, setManualTitle] = useState('');
  const [manualSource, setManualSource] = useState('');
  const [duration, setDuration] = useState(-1)
  const [recievedclaim, setrecievedclaim] = useState(false)

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const toggleExpansion = (support) => {
    setExpandedGroups({
      ...expandedGroups,
      [support]: !expandedGroups[support]
    });
  };

  const handleFormExpandClick = () => {
    setShowTextField(true);
  };


  const handlePopupSubmit = () => {
    setTitle(`${manualTitle} by the source: ${manualSource}`);
    setShowPopup(false);
  };

  async function sendFeedback() {
    console.log(id)
    try {
      const apiUrl = 'https://facticity-backend-a4xncz5jja-as.a.run.app/SendDetailedFeedback';
      const requestBody = { id: { id: id }, response: value };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        console.error('Success');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  

  const getColor = (classification) => {
    switch (classification) {
      case 'True':
        return 'success';
      case 'Processing':
        return 'warning';
      case 'False':
        return 'error';
      case 'Inconclusive':
        return 'warning';
      case 'Unverifiable':
        return 'warning';
      case '':
        return 'info';
      default:
        return 'info';
    }
  };

  const badgeColor = getColor(classification);

  const setDone = (claim, classification) => {
    setClaims(prevClaims => 
      prevClaims.map(c => 
        c.claim === claim ? { ...c, done: true , classification:classification} : c
      )
    );
  }



  const addClaims = async (claims) => {
    for (let newClaim of claims) {
      setClaims(prevClaims => {
        // Check if the claim already exists in the state
        const claimExists = prevClaims.some(claimObj => claimObj.claim === newClaim);
        if (!claimExists) {
          return [...prevClaims, {claim: newClaim, process:false, done:false}];
        }
        return prevClaims;
      });
      await new Promise(resolve => setTimeout(resolve, 100)); // 1-second delay
    }
  };
  
  function durationToSeconds(duration) {
    const regex = /P(?:([0-9]+)Y)?(?:([0-9]+)M)?(?:([0-9]+)D)?T(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9]+)S)?/;
    const matches = duration.match(regex);
  
    const years = parseInt(matches[1]) || 0;
    const months = parseInt(matches[2]) || 0;
    const days = parseInt(matches[3]) || 0;
    const hours = parseInt(matches[4]) || 0;
    const minutes = parseInt(matches[5]) || 0;
    const seconds = parseInt(matches[6]) || 0;
  
    // Convert all the units to seconds
    const totalSeconds = 
      (years * 31536000) +  // Approximate number of seconds in a year
      (months * 2592000) +  // Approximate number of seconds in a month (30 days)
      (days * 86400) +
      (hours * 3600) +
      (minutes * 60) +
      seconds;
  
    return totalSeconds;
  }
  

  useEffect(() => {
    const interval = setInterval(() => {
        const processed = claims.filter(c => c.done).length;
        const nextBatchStart = Math.floor(processed / 5) * 5;
        const nextBatchEnd = Math.min(nextBatchStart + 5, claims.length);

      setProcessedClaims(processed); // update the local state

      setClaims(prevClaims =>
        prevClaims.map((c, index) =>
          index >= nextBatchStart && index < nextBatchEnd
            ? { ...c, process: true }
            : c
        )
      );
    //   console.log(nextBatchStart,nextBatchEnd)
    }, 1000); // Check every 1 second

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [claims]);
//   useEffect(() => {
//     const processedClaims = claims.filter(c => c.done).length;
//     const nextBatchStart = Math.floor(processedClaims / 5) * 5;
//     const nextBatchEnd = nextBatchStart + 5;

//     setClaims(prevClaims =>
//       prevClaims.map((c, index) =>
//         index >= nextBatchStart && index < nextBatchEnd
//           ? { ...c, process: true }
//           : c
//       )
//     );
//   }, [total]);
  
  
  useEffect(() => {
    const fetchData = async () => {
      setReady(false)
      setClaims([])
      setCount({'True':0, 'False':0, 'Inconclusive':0})
      try {
        const apiUrl = `${defaultUrl}/ProcessEverything`;
        const requestBody = {
          query: claim,
          location: "",
          timestamp: "",
          userEmail: email,
          requestID: uuidv4()
        };

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data)
        setrecievedclaim(true)
        await addClaims(data.claims)
        setReady(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [claim]);

  const renderClaims = () => {
    const scrollableContainerStyle = {
      maxHeight: '80vh', // Set your desired maximum height
      overflowY: 'auto',
    };
  
    return (
      <div style={scrollableContainerStyle}>
        {claims.map((claim_i, index) => (
          <ExpandComponentUpdated key={index} claim={claim_i.claim} email={email} process={claim_i.process} done={claim_i.done} setDone={setDone} video={claim_i} source = {title}/>
        ))}
      </div>
    );
  };

  const groupedSources = sources.reduce((acc, source, index) => {
    const support = source.support || 'Unknown';

    if (!acc[support]) {
      acc[support] = [];
    }

    acc[support].push(...source.sources.map(link => link.endsWith(',') ? link.slice(0, -1) : link));

    return acc;
  }, {});

  const classificationCounts = useMemo(() => {
    return claims.reduce(
      (acc, claim) => {
        acc[claim.classification] = (acc[claim.classification] || 0) + 1;
        return acc;
      },
      { True: 0, False: 0, Inconclusive: 0 }
    );
  }, [claims]);


  useEffect(() => {
    const fetchVideoTitle = async () => {
        try {
            if (isValidUrl(claim)) {
                const title = await getYoutubeVideoDetails(claim);
                setTitle(`${title.title} by the Youtube channel: ${title.channel}`);
                var dur = durationToSeconds(title.duration)
                console.log(`${title.title} by the Youtube channel: ${title.channel} +${title.duration}+${dur}`)
                setDuration(dur)
            } else {
                setDuration(60)
                // setShowPopup(true);
            }
        } catch (error) {
            setDuration(60)
            console.error('Error fetching video title:', error);
            // setShowPopup(true);
        }
    };

    fetchVideoTitle();
}, [claim]);


const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (e) {
        return false;
    }
};
const isValidYouTubeUrl = (url) => {
    const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
  };

  return (
    <Card variant="outlined" style={{ marginBottom: '10px', fontFamily: 'IBM Plex Sans' }}>
    <div>
        <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
            <DialogTitle>Enter Source and Title</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the title and source for the video.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Title"
                    type="text"
                    fullWidth
                    value={manualTitle}
                    onChange={(e) => setManualTitle(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Source"
                    type="text"
                    fullWidth
                    value={manualSource}
                    onChange={(e) => setManualSource(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowPopup(false)}>Cancel</Button>
                <Button onClick={handlePopupSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    </div>
    <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                {isValidYouTubeUrl(claim) ? (
                    <YouTubeEmbed videoLink={claim} height={'40vh'} />
                ) : (
                    <Typography variant="body1">{claim}</Typography>
                )}
            <Box display="inline-flex" justifyContent="flex-start" p={2}>
                <Chip
                    label={`True: ${classificationCounts.True}`}
                    style={{ backgroundColor: '#34C759', color: 'white', marginRight: '8px' }}
                />
                <Chip
                    label={`False: ${classificationCounts.False}`}
                    style={{ backgroundColor: '#FF3B30', color: 'white', marginRight: '8px' }}
                />
                <Chip
                    label={`Unverifiable: ${classificationCounts.Inconclusive}`}
                    style={{ backgroundColor: '#FF9500', color: 'black' }}
                />
            </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'IBM Plex Sans', fontSize: '22px' }}>
                        {claim}
                    </Typography>
                </div> */}
                <Typography variant="body1">
                {ready === false && duration >= 0 && <VideoProcessingLoader videoDuration={duration} done={recievedclaim}/>}

                </Typography>
                <div>
                    {renderClaims()}
                </div>
            </Grid>
            </Grid>
        </CardContent>
    </Card>
  );
});

export default VideoParagraphComponent;
